import { t } from 'localization'
import { MouseEvent } from 'react'
import { Button } from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import { colors, fonts } from 'shared/lib'
import styled from 'styled-components'

const TP = 'checkout.components.ShippingAddressPanel.PriceUpdateModal'

type PriceUpdateModalProps = {
  isOpen: boolean
  onClose?: (event: MouseEvent<HTMLElement>) => void
}

export const PriceUpdateModal = ({ isOpen, onClose }: PriceUpdateModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <Header data-qa="priceUpdateModalHeader">
          {t(`${TP}.priceUpdateModalHeader`, 'Price Update')}
        </Header>
        <Description data-qa="priceUpdateModalDescription">
          {t(
            `${TP}.priceUpdateModalDescription`,
            'Your revised shipping destination has resulted in changes to your order total. Please review your price update prior to checkout.',
          )}
        </Description>
        <OkButton
          type="button"
          $fill
          buttonType="primary1"
          onClick={onClose}
          qaAttr={'PriceUpdateModalOkButton'}
        >
          {t(`${TP}.okButton`, 'Ok')}
        </OkButton>
      </ModalContent>
    </Modal>
  )
}

const ModalContent = styled.div`
  background-color: ${colors.FC2_WHITE};
  border: 1px solid ${colors.FC2_LIGHTEST_GREY};
  height: fit-content;
  padding: 20px;
  width: 100%;
`

const Header = styled.h1`
  ${fonts.SUBTITLE_1}
  font-weight: 500;
  margin: 0 0;
`

const Description = styled.p`
  ${fonts.BODY_TEXT}
  color: ${colors.FC2_GREY};
  font-weight: 400;
  line-height: 18px;
  margin: 10px 0 0 0;
  text-align: left;
`

const OkButton = styled(Button)`
  margin: 40px 0px 10px 0px;
`
