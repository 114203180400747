import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { FavoritesSlideOutPanel } from 'favorites/FavoritesSlideOutPanel'
import { LoginInstructions } from 'favorites/LoginInstructions'
import { LoginPanelModal } from 'favorites/LoginPanelModal'
import NavLink from 'layout/navigation/components/NavLink'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { sizes } from 'shared/lib/media'
import { t } from 'localization'
import { useAppSelector } from 'store/hooks'
import { useUser } from 'shared/hooks/useUser'

export const FavoritesNavLink = ({
  closeNav,
  navTheme = 'desktop',
  id,
}: {
  closeNav?: () => void
  navTheme?: string
  id: string
}) => {
  const TP = 'favorites.components.FavoritesNavLink'
  const [renderPortal, setRenderPortal] = useState(false)
  const [isLoginPanelModalOpen, setIsLoginPanelModalOpen] = useState(false)

  const { isAuthenticated } = useUser()
  const { userLoggedInFromFavorites } = useAppSelector((state) => state.favorites)

  const handleOpen = () => {
    if (closeNav) {
      closeNav()
    }
    setRenderPortal(true)
    sendTrackingEvent('HEADER_NAVIGATION_LINK_TAP', { section_name: 'Favorites' })
  }

  const handleCloseLoginPanelModal = () => {
    setIsLoginPanelModalOpen(false)
  }

  const handleOpenLoginPanelModal = () => {
    setIsLoginPanelModalOpen(true)
  }

  useEffect(() => {
    if (isAuthenticated && userLoggedInFromFavorites) {
      setRenderPortal(true)
      setIsLoginPanelModalOpen(false)
    }
  }, [isAuthenticated, userLoggedInFromFavorites])

  return (
    <>
      {(navTheme === 'mobile' || navTheme === 'desktop') && (
        <StyledNavLink data-qa={`FavoritesNavLink${id}`} onClick={handleOpen}>
          {t(`${TP}.navLinkText`, 'Favorites')}
        </StyledNavLink>
      )}

      {isLoginPanelModalOpen && (
        <LoginPanelModal
          handleCloseLoginPanelModal={handleCloseLoginPanelModal}
          location="global_favorite_panel"
        />
      )}
      {!isAuthenticated && renderPortal && (
        <LoginInstructions
          data-qa="LoginInstructions"
          setRenderPortal={setRenderPortal}
          handleOpenLoginPanelModal={handleOpenLoginPanelModal}
        />
      )}
      {isAuthenticated && renderPortal && (
        <FavoritesSlideOutPanel setRenderPortal={setRenderPortal} />
      )}
    </>
  )
}

const StyledNavLink = styled(NavLink)`
  @media (max-width: ${sizes.large / 16}em) {
    color: #333333;
    font-weight: 400;
    padding: 12px 0px;
  }
`
