import { createApi } from '@reduxjs/toolkit/query/react'

import { clientRequestService } from 'shared/services/clientRequestService'

export const apiSlice = createApi({
  baseQuery: clientRequestService(),
  // Initialize an empty api service that we'll inject endpoints into later as needed
  // Docs: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
  endpoints: () => ({}),
  reducerPath: 'api',
  tagTypes: ['Address', 'Favorites', 'Order'],
})
