/* tslint:disable:max-line-length */

enum PagesQA {
  // ABOUT US (footer: OUR HISTORY)
  AboutUs = 'AboutUs', // DO NO USE as it is, use Auto-generated names
  // AboutUsPageTitle // Auto-generated
  AboutUsFcnyLink = 'AboutUsFcnyLink',
  AboutUsFclaLink = 'AboutUsFclaLink',
  AboutUsFcmiaLink = 'AboutUsFcmiaLink',
  // STORE LOCATOR (footer: STORES)
  StoresPageTitle = 'StoresPageTitle',
  BreadcrumbFlightClub = 'BreadcrumbFlightClub',
  BreadcrumbStore = 'BreadcrumbStore',
  BreadcrumbStoreName = 'BreadcrumbStoreName',
  ConsignmentAddress = 'ConsignmentAddress',
  // FcnyStorePricing // Auto-generated
  // FcnyStoreAddress // Auto-generated
  // FcnyStoreMapLink // Auto-generated
  // FclaStorePricing // Auto-generated
  // FclaStoreAddress // Auto-generated
  // FclaStoreMapLink // Auto-generated
  // FcmiaStorePricing // Auto-generated
  // FcmiaStoreAddress // Auto-generated
  // FcmiaStoreMapLink // Auto-generated
  // SELL WITH FLIGHT CLUB (footer: CONSIGNMENT)
  SellWithFlightclub = 'SellWithFlightclub', // DO NO USE as it is, use Auto-generated names
  // SellWithFlightclubPageTitle // Auto-generated
  ConsignCreateYouAccountHereLink = 'ConsignCreateYouAccountHereLink',
  ConsignFaqLink = 'ConsignFaqLink',
  ConsignTermsConditionsLink = 'ConsignTermsConditionsLink',
  ConsignContactUsLink = 'ConsignContactUsLink',
  // PRESS & MEDIA REQUESTS (footer: MEDIA RELATIONS)
  PressAndMedia = 'PressAndMedia', // DO NO USE as it is, use Auto-generated names
  // PressAndMediaPageTitle // Auto-generated
  MediaEmailLink = 'MediaEmailLink',
  // JOBS (footer: JOBS)
  Jobs = 'Jobs', // DO NO USE as it is, use Auto-generated names
  // JobsPageTitle // Auto-generated
  ExploreJobsLink = 'ExploreJobsLink',
  // Seller Terms of Use
  SellerTermsOfUse = 'SellerTermsOfUse', // DO NO USE as it is, use Auto-generated names
  // SHIPPING & RETURNS (footer: SHIPPING & RETURNS)
  ShippingAndReturn = 'ShippingAndReturn', // DO NO USE as it is, use Auto-generated names
  // ShippingAndReturnPageTitle // Auto-generated
  ShippingReturnContactUsLink = 'ShippingReturnContactUsLink',
  // FLIGHT CLUB TERMS OF USE (footer: TERMS OF USE)
  TermsOfUse = 'TermsOfUse', // DO NO USE as it is, use Auto-generated names
  // TermsOfUsePageTitle // Auto-generated
  // FLIGHT CLUB PRIVACY POLICY (footer: PRIVACY POLICY)
  PrivacyPolicy = 'PrivacyPolicy', // DO NO USE as it is, use Auto-generated names
  // PrivacyPolicyPageTitle // Auto-generated
  // SIZE GUIDE (footer: SIZE GUIDE)
  SizeGuidePageTitle = 'SizeGuidePageTitle',
  SizeGuideDescription = 'SizeGuideDescription',
  SizeGuideSupportEmail = 'SizeGuideSupportEmail',
  // GRID accross PAGES
  GridTitle = 'GridTitle',
  GridDescription = 'GridDescription',
  // OLD deprecated?
  TermsConditionsOld2019 = 'TermsConditionsOld2019',
  TermsConditions = 'TermsConditions',
  DynamicSlug = 'DynamicSlug',
}

export default PagesQA
