import { apiSlice } from 'api/apiSlice'
import { FavoriteGroupedByPT, FavoritesPartialProductTemplate } from 'favorites/types'

// Create a new api by injecting endpoints into the root api slice
// Ref: https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const productTemplateListsApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    fetchUserFavoritesForPT: builder.query<
      { sizes: number[]; productTemplate: FavoritesPartialProductTemplate },
      { productTemplateId: string; currentUserId: number | undefined }
    >({
      query: ({ productTemplateId }) => ({
        app: 'product-template-lists',
        url: `/web-api/v1/product-template-lists/get-favorites/${productTemplateId}?timestamp=${new Date().getTime()}`,
        method: 'GET',
      }),
      providesTags: ['Favorites'],
      forceRefetch({ currentArg, previousArg }) {
        return currentArg?.currentUserId !== previousArg?.currentUserId
      },
    }),
    saveUserFavorites: builder.mutation<
      { success: boolean },
      { sizes: number[]; productTemplateId: string }
    >({
      query: (body) => ({
        app: 'product-template-lists',
        data: body,
        method: 'POST',
        url: `/web-api/v1/product-template-lists/save-favorites`,
      }),
      invalidatesTags: ['Favorites'],
    }),
    fetchAllUserFavorites: builder.query<
      { listItems: FavoriteGroupedByPT[]; itemsPerPage: number; paginationToken?: string },
      { paginationToken?: string }
    >({
      query: ({ paginationToken = '' }) => ({
        app: 'product-template-lists',
        method: 'GET',
        url: `/web-api/v1/product-template-lists/get-all-favorites?pagination_token=${paginationToken}&timestamp=${new Date().getTime()}`,
      }),
      providesTags: ['Favorites'],
    }),
    deleteFavorite: builder.mutation<
      { success: boolean },
      { productTemplateId: string; sizes: number[] }
    >({
      query: (body) => ({
        app: 'product-template-lists',
        data: body,
        method: 'DELETE',
        url: '/web-api/v1/product-template-lists/delete-favorite',
      }),
      invalidatesTags: ['Favorites'],
    }),
  }),
})

export const {
  useFetchUserFavoritesForPTQuery,
  useFetchAllUserFavoritesQuery,
  useSaveUserFavoritesMutation,
  useDeleteFavoriteMutation,
} = productTemplateListsApi
