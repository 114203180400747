import { PreloadedState, combineReducers, configureStore } from '@reduxjs/toolkit'

import { apiSlice } from 'api/apiSlice'
import { authReducer } from 'store/authSlice'
import { checkoutReducer } from 'store/checkoutSlice'
import { favoritesReducer } from 'store/favoritesSlice'

export const rootReducer = combineReducers({
  [apiSlice.reducerPath]: apiSlice.reducer,
  auth: authReducer,
  checkout: checkoutReducer,
  favorites: favoritesReducer,
})

export const setupStore = (preloadedState: PreloadedState<RootState>) =>
  configureStore({
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
    preloadedState,
    reducer: rootReducer,
  })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
