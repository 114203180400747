/* tslint:disable:max-line-length */
enum IconsQA {
  AfterPayIcon = 'AfterPayIcon',
  AffirmIcon = 'AffirmIcon',
  PaypalIcon = 'PaypalIcon',
  AmexCardIcon = 'AmexCardIcon',
  DiscoverCardIcon = 'DiscoverCardIcon',
  MasterCardIcon = 'MasterCardIcon',
  VisaCardIcon = 'VisaCardIcon',
}

export default IconsQA
