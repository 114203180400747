import { useRef, useEffect, useState, MutableRefObject, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

type ClientOnlyPortalProps = {
  children: ReactNode
  selector: string
  withBackdrop?: boolean
  itemsAlignment?: 'center' | 'end'
  id?: string
}

export const ClientOnlyPortal = ({
  children,
  selector,
  withBackdrop = true,
  itemsAlignment = 'center',
  id,
}: ClientOnlyPortalProps) => {
  const ref: MutableRefObject<Element | null> = useRef(null)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    ref.current = document.querySelector(selector)
    setMounted(true)
  }, [selector])

  useEffect(() => {
    const htmlElement = document.querySelector('html')

    if (htmlElement) {
      htmlElement.style.overflow = 'hidden'
    }

    return () => {
      const portalsCurrentlyOpen = document.querySelectorAll('[id^="portal-backdrop"]')

      if (portalsCurrentlyOpen?.length < 1) {
        if (htmlElement) {
          htmlElement.style.overflow = ''
        }
      }
    }
  }, [id])

  return mounted && ref.current
    ? createPortal(
        <Backdrop
          id={`portal-backdrop-${id}`}
          data-qa="PortalBackdrop"
          $itemsAlignment={itemsAlignment}
          $withBackdrop={withBackdrop}
        >
          {children}
        </Backdrop>,
        ref.current,
      )
    : null
}

const Backdrop = styled.div<{
  $itemsAlignment: ClientOnlyPortalProps['itemsAlignment']
  $withBackdrop?: boolean
}>`
  ${({ $withBackdrop }) => $withBackdrop && ' background-color: rgba(0, 0, 0, 0.4)'};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: ${({ $itemsAlignment }) => $itemsAlignment};
  justify-content: ${({ $itemsAlignment }) => $itemsAlignment};
  z-index: 2000;
  height: 100%;
`
