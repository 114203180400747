import Link from 'next/link'
import { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { t } from 'localization'
import { headerNavigationTrackEvent } from 'mParticle/headerNavigationTrackEvent'
import { useAuth } from 'shared/hooks/useAuth'
import { useUser } from 'shared/hooks/useUser'
import { BodyText } from 'shared/text'
import { colors } from 'shared/theme'
import { NavThemeNames } from './Navigation'
import HoverIntent from './components/HoverIntent'
import { UnstyledLink } from './components/MenuLink'

const TP = 'layout.navigation.accountMenu'

interface AccountMenuProps {
  closeMenu: () => void
  navTheme: NavThemeNames
}

const accountMenuActiveThemes = new Set<NavThemeNames>(['default', 'overlayed', 'scrolled'])

const AccountMenu = ({ closeMenu, navTheme }: AccountMenuProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const { currentUser, isAuthenticated } = useUser()
  const { logoutUser } = useAuth()
  const showAsAuthenticated = isAuthenticated && !currentUser?.isGuest

  const handleHover = () => {
    if (!accountMenuActiveThemes.has(navTheme)) {
      closeMenu()
    }
    setIsOpen(true)
  }

  const handleClickSignOut = async (e) => {
    headerNavigationTrackEvent(e)
    await logoutUser()
  }

  useEffect(() => {
    if (!accountMenuActiveThemes.has(navTheme)) {
      setIsOpen(false)
    }
  }, [navTheme])

  useEffect(() => {
    if (currentUser?.isGuest) {
      const logout = async () => await logoutUser()
      logout()
    }
  }, [currentUser])

  return (
    <HoverIntent onEnter={handleHover} onExit={() => setIsOpen(false)}>
      <Container>
        <Link href="/my-account" passHref legacyBehavior>
          <AccountLink
            data-qa="DesktopNavigationAccountMenu"
            isOpen={isOpen}
            onClick={(e) => headerNavigationTrackEvent(e)}
          >
            {t(`${TP}.account`, 'Account')}
          </AccountLink>
        </Link>
        <Menu isOpen={isOpen}>
          <Link href="/my-account" passHref legacyBehavior>
            <StyledA onClick={(e) => headerNavigationTrackEvent(e)}>
              <MenuLink data-qa="DesktopNavigationAccountMenuMyAccountLink">
                {t(`${TP}.myAccount`, 'My Account')}
              </MenuLink>
            </StyledA>
          </Link>
          {showAsAuthenticated ? (
            <Link href="/my-account" passHref legacyBehavior>
              <StyledA onClick={(e) => headerNavigationTrackEvent(e)}>
                <MenuLink data-qa="DesktopNavigationAccountMenuMyOrdersLink">
                  {t(`${TP}.myOrders`, 'My Orders')}
                </MenuLink>
              </StyledA>
            </Link>
          ) : (
            <Link href="/order-search" passHref legacyBehavior>
              <StyledA onClick={(e) => headerNavigationTrackEvent(e)}>
                <MenuLink data-qa="DesktopNavigationAccountMenuTrackOrderLink">
                  {t(`${TP}.myOrders`, 'My Orders')}
                </MenuLink>
              </StyledA>
            </Link>
          )}
          <Link href="https://support.flightclub.com/hc/en-us" passHref legacyBehavior>
            <StyledA onClick={(e) => headerNavigationTrackEvent(e)}>
              <MenuLink data-qa="DesktopNavigationAccountMenuSupportLink">
                {t(`${TP}.support`, 'Support')}
              </MenuLink>
            </StyledA>
          </Link>
          {showAsAuthenticated ? (
            <LastMenuLink
              data-qa="DesktopNavigationAccountMenuSignOutLink"
              onClick={handleClickSignOut}
            >
              {t(`${TP}.signOut`, 'Sign Out')}
            </LastMenuLink>
          ) : (
            <Link href="/customer/account/login" passHref legacyBehavior>
              <StyledA onClick={(e) => headerNavigationTrackEvent(e)}>
                <LastMenuLink data-qa="DesktopNavigationAccountMenuSignInLink">
                  {t(`${TP}.signUp`, 'Sign Up')}
                </LastMenuLink>
              </StyledA>
            </Link>
          )}
        </Menu>
      </Container>
    </HoverIntent>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  height: 76px;
`

const AccountLink = styled.a<{ isOpen: boolean }>`
  color: ${(props) => props.theme.textColor};
  font-size: 16px;
  font-weight: 500;
  padding: 12px 24px 12px 12px;
  text-decoration: none;
  cursor: pointer;

  ${(props) =>
    props.isOpen &&
    css`
      color: ${colors.mediumGray};
    `}
`

const StyledA = styled(UnstyledLink)`
  &:first-child {
    ${(props) =>
      props.theme.backgroundColor === colors.transparent &&
      css`
        margin-top: -15px;
      `}
  }

  &:last-child {
    padding-bottom: 30px;
  }
`

export const MenuLink = styled(BodyText)`
  display: block;
  text-align: right;
  color: ${(props) => props.theme.textColor};
  white-space: nowrap;
  padding: 7.5px 24px;
  cursor: pointer;

  &:hover {
    color: ${(props) =>
      props.theme.textColor === colors.white ? colors.lighterGray : colors.darkGray};
  }
`

const LastMenuLink = styled(MenuLink)`
  padding-bottom: 25px;
`

const Menu = styled.nav<{ isOpen: boolean }>`
  position: absolute;
  top: 65px;
  right: 0;
  max-height: 0;
  min-width: 170px;
  overflow: hidden;
  transition: max-height 200ms ease-out;
  background-color: ${(props) => props.theme.backgroundColor};

  ${(props) =>
    props.isOpen &&
    css`
      max-height: 300px;
    `}
`

export default AccountMenu
