import { FC } from 'react'
import AmexCardIcon from './AmexCardIcon'
import DiscoverCardIcon from './DiscoverCardIcon'
import MasterCardIcon from './MasterCardIcon'
import VisaCardIcon from './VisaCardIcon'

export enum CardBrand {
  Visa = 'Visa',
  MasterCard = 'MasterCard',
  AmericanExpress = 'American Express',
  Discover = 'Discover',
}

interface ICardIcon {
  brand: string
  className: string
  width?: number
  height?: number
}

const CardIcon: FC<ICardIcon> = ({ brand, width, height, className }) =>
  brand === CardBrand.Visa ? (
    <VisaCardIcon height={height} width={width} className={className} />
  ) : brand === CardBrand.MasterCard ? (
    <MasterCardIcon height={height} width={width} className={className} />
  ) : brand === CardBrand.AmericanExpress ? (
    <AmexCardIcon height={height} width={width} className={className} />
  ) : brand === CardBrand.Discover ? (
    <DiscoverCardIcon height={height} width={width} className={className} />
  ) : null

export default CardIcon
