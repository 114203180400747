import { map, snakeCase } from 'lodash/fp'

export enum FeatureFlag {
  // Permanent Flags
  // ---------------------------------------------------------------------------
  PROMO_CODES_GUEST = 'webFcPromoCodesGuest',
  PROMO_CODES_USER = 'webFcPromoCodes',
  WEB_FC_BANNER = 'webFcBanner',
  WEB_FC_ENABLE_EXPERIMENTS = 'webFcEnableExperiments',
  WEB_FC_ENABLE_PAYPAL = 'webFcEnablePaypal',
  WEB_FC_ENABLE_KLARNA = 'webFcEnableKlarna',
  WEB_FC_ENABLE_GOOGLE_RECAPTCHA = 'webFcEnableGoogleRecaptcha',
  WEB_FC_ENABLE_PRECHECK_EMAIL_SIGNUP = 'webFcEnablePrecheckEmailSignup',
  // Temporary Flags
  // ---------------------------------------------------------------------------
  CREDIT_CARD_VERIFICATION = 'webFcCreditCardVerification',
  FORCE_HIDE_ONETRUST = 'tempWebFcForceHideOneTrust',
  GUEST_CHECKOUT = 'tempWebFcGuestCheckout',
  HIDE_HIGH_PRICED_DEFECT_PRODUCT = 'tempWebFcHideHighPricedDefectProduct',
  HIDE_HIGH_PRICED_USED_PRODUCT = 'tempWebFcHideHighPricedUsedProduct',
  IMPACT_TRACKING = 'tempWebFcImpactTracking',
  IVAR_FONT = 'tempWebFcIvarFont',
  LOCALIZATION = 'tempWebFcLocalization',
  MARKET_PRICING = 'tempWebFcMarketPricing',
  ONE_TRUST = 'webFcOneTrust',
  SHIPPING_DELAY = 'tempWebFcShippingDelay',
  STORYTELLING_GLOBAL = 'tempWebFcStorytellingGlobal',
  STORYTELLING_PHASE1 = 'tempWebFcStorytellingPhaseOne',
  STRIPE_BILLING_ADDRESS_VALIDATION = 'tempWebFcStripeAddressValidation',
  TEMP_WEB_FC_AFFIRM_WITH_LOCALE = 'tempWebFcAffirmWithLocale',
  TEMP_WEB_FC_CHECKOUT_RTK = 'tempWebFcCheckoutRtk',
  TEMP_WEB_FC_ENABLE_CONSTRUCTOR_BEACON = 'tempWebFcEnableConstructorBeacon',
  TEMP_WEB_FC_FLIGHT_CLUB_FRIDAYS = 'tempWebFcFlightClubFridays',
  TEMP_WEB_FC_HOLIDAY_SHIPPING_2022_NEXT_DAY = 'tempWebFcHolidayShipping2022NextDay',
  TEMP_WEB_FC_HOLIDAY_SHIPPING_2022_STANDARD = 'tempWebFcHolidayShipping2022Standard',
  TEMP_WEB_FC_HOME_FEED_VARIANT = 'tempWebFcHomeFeedVariant',
  TEMP_WEB_FC_PDP_GLOBAL_PRICING_COPY = 'tempWebFcPdpGlobalPricingCopy',
  TEMP_WEB_FC_PAYPAL_TWO = 'tempWebFcPaypalTwo',
  WEB_FC_CHECKOUT_MARKET_PRICING = 'webFcCheckoutMarketPricing',
  WEB_FC_ORDER_CANCELLATION = 'webFcOrderCancellation',
  TEMP_WEB_FC_HREFLANG = 'tempWebFcHreflang',
  TEMP_WEB_FC_JP_SELLING_PAGE = 'tempWebFcJpSellingPage',
  TEMP_WEB_FC_ENABLE_EXPRESS_AFFIRM = 'tempWebFcEnableExpressAffirm',
  TEMP_WEB_FC_ENABLE_EXPRESS_AFTERPAY = 'tempWebFcEnableExpressAfterpay',
  TEMP_WEB_FC_ENABLE_EXPRESS_KLARNA = 'tempWebFcEnableExpressKlarna',
  TEMP_WEB_FC_ENABLE_FAVORITES = 'tempWebFcEnableFavorites',
  TEMP_WEB_FC_ENABLE_FULLY_LANDED_PRICING_COPY_EXPERIMENT = 'tempWebFcEnableFullyLandedPricingCopyExperiment',
  TEMP_WEB_FC_ENABLE_MORE_ORDER_INFO = 'tempWebFcEnableMoreOrderInfo',
  TEMP_WEB_FC_ENABLE_NEW_COLLECTION_LINK_STYLE = 'tempWebFcEnableNewCollectionLinkStyle',
  TEMP_WEB_FC_ENABLE_OFFERS = 'tempWebFcEnableOffers',
  TEMP_WEB_FC_ENABLE_PAYMENT_AVAILABILITY_SERVICE = 'tempWebFcEnablePaymentAvailabilityService',
  TEMP_WEB_FC_ENABLE_FC_STRIPE_API_KEY = 'tempWebFcEnableFcStripeApiKey',
  TEMP_WEB_FC_ENABLE_THUMB_GALLERY = 'tempWebFcEnableThumbGallery',
  TEMP_WEB_FC_ENABLE_CREATE_ACCOUNT_GUEST_CHECKOUT = 'tempWebFcEnableCreateAccountGuestCheckout',
  TEMP_WEB_FC_ENABLE_STORE_PAGE_REDESIGNED = 'tempWebFcEnableStorePageRedesigned',
  TEMP_WEB_FC_PDP_HIDE_FASTEST_TO_YOU = 'tempWebFcPdpHideFastestToYou'
}

// An iterable form of the featureFlags
export const featureFlags = Object.keys(FeatureFlag).map((featureFlag) => snakeCase(featureFlag))

export default map(snakeCase)(FeatureFlag)
