import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { MobileNavLinks } from 'layout/navigation/components/MobileNavLinks'
import { t } from 'localization'
import { headerNavigationTrackEvent } from 'mParticle/headerNavigationTrackEvent'
import Link from 'next/link'
import { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { useAuth } from 'shared/hooks/useAuth'
import { useUser } from 'shared/hooks/useUser'
import { sizes } from 'shared/lib/media'
import { H6 } from 'shared/text'
import { colors } from 'shared/theme'
import styled from 'styled-components'
import ExpandedMenuModal from './components/ExpandedMenuModal'
import { MenuLink, UnstyledLink } from './components/MenuLink'

const TP = 'layout.navigation.mobileMenu'

interface MobileMenuProps {
  isOpen: boolean
  closeMenu: () => void
}

const AccountLinkList = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Section = styled.div`
  padding: 25px 20px;
`

const PaddedMenuLink = styled(MenuLink)`
  width: 50%;
  padding: 10px 0;
`

const RedPaddedMenuLink = styled(PaddedMenuLink)`
  color: ${colors.red};
`

const Title = styled(H6)`
  margin-bottom: 20px;
`

const StoreTextLine = styled.span`
  font-size: 16px;
  line-height: 20px;
  display: block;
`

const StoreContainer = styled.div`
  margin: 20px 0;
`

const MobileMenu = ({ isOpen, closeMenu }: MobileMenuProps) => {
  const displayFlightClubFridays = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_FLIGHT_CLUB_FRIDAYS)
  const { currentUser, isAuthenticated } = useUser()
  const { logoutUser } = useAuth()
  const isMobile = useMediaQuery({ maxWidth: sizes.small })

  const handleSignOut = async (e: React.MouseEvent<HTMLElement>) => {
    headerNavigationTrackEvent(e)
    if (isAuthenticated) {
      await logoutUser()
      closeMenu()
    }
  }

  const handleLinkClick = (e: React.MouseEvent<HTMLElement>) => {
    headerNavigationTrackEvent(e)
    closeMenu()
  }

  useEffect(() => {
    if (!isMobile && isOpen) {
      closeMenu()
    }
  }, [isMobile, isOpen])

  useEffect(() => {
    if (currentUser?.isGuest) {
      const logout = async () => await logoutUser()
      logout()
    }
  }, [currentUser])

  return (
    <ExpandedMenuModal
      isOpen={isOpen}
      contentLabel={t(`${TP}.mobileMenu`, 'Mobile Menu')}
      qaAttr="MobileMenu"
    >
      <Section>
        <Title>{t(`${TP}.shop`, 'Shop')}</Title>
        <MobileNavLinks closeMenu={closeMenu} />
      </Section>
      {displayFlightClubFridays && (
        <Section>
          <UnstyledLink
            data-qa="flightclub_fridays_youtube"
            href="https://www.youtube.com/playlist?list=PL9jt6WcuokxZA9n-fCgwLJXZ3UFU6T6WX"
            onClick={(e) => headerNavigationTrackEvent(e)}
            target="_blank"
          >
            <Title>{t(`${TP}.fcFridays`, 'FC Fridays')}</Title>
          </UnstyledLink>
        </Section>
      )}
      <Section>
        <Title>{t(`${TP}.account`, 'Account')}</Title>
        <AccountLinkList>
          <Link href="/my-account" passHref legacyBehavior>
            <PaddedMenuLink
              data-qa="MobileNavigationAccountMenuMyAccountLink"
              onClick={handleLinkClick}
            >
              {t(`${TP}.myAccount`, 'My Account')}
            </PaddedMenuLink>
          </Link>
          <Link href="https://support.flightclub.com/hc/en-us" passHref legacyBehavior>
            <PaddedMenuLink
              data-qa="MobileNavigationAccountMenuSupportLink"
              onClick={handleLinkClick}
            >
              {t(`${TP}.support`, 'Support')}
            </PaddedMenuLink>
          </Link>
          <Link href={!isAuthenticated ? '/order-search' : '/my-account'} passHref legacyBehavior>
            <PaddedMenuLink
              data-qa="MobileNavigationAccountMenuMyOrdersLink"
              onClick={handleLinkClick}
            >
              {t(`${TP}.myOrders`, 'My Orders')}
            </PaddedMenuLink>
          </Link>
          <Link href={!isAuthenticated ? '/order-search' : '/my-account'} passHref legacyBehavior>
            <PaddedMenuLink
              data-qa="MobileNavigationAccountMenuTrackOrderLink"
              onClick={handleLinkClick}
            >
              {t(`${TP}.trackMyOrder`, 'Track My Order')}
            </PaddedMenuLink>
          </Link>
          <Link href="https://sell.flightclub.com/" passHref legacyBehavior>
            <PaddedMenuLink
              data-qa="MobileNavigationAccountMenuSellSneakersLink"
              onClick={handleLinkClick}
            >
              {t(`${TP}.sellSneakers`, 'Sell Sneakers')}
            </PaddedMenuLink>
          </Link>
          {isAuthenticated ? (
            <RedPaddedMenuLink
              data-qa="MobileNavigationAccountMenuSignOutLink"
              onClick={handleSignOut}
            >
              {t(`${TP}.signOut`, 'Sign Out')}
            </RedPaddedMenuLink>
          ) : (
            <Link href="/customer/account/login" passHref legacyBehavior>
              <PaddedMenuLink
                data-qa="MobileNavigationAccountMenuSignInLink"
                onClick={handleLinkClick}
              >
                {t(`${TP}.signUp`, 'Sign Up')}
              </PaddedMenuLink>
            </Link>
          )}
          <PaddedMenuLink>&nbsp;</PaddedMenuLink>
        </AccountLinkList>
      </Section>
      <Link href="/storelocator" passHref legacyBehavior>
        <UnstyledLink onClick={(e) => headerNavigationTrackEvent(e)}>
          <Section onClick={closeMenu}>
            <Title>{t(`${TP}.retailStores`, 'Retail Stores')}</Title>
            <StoreContainer>
              <StoreTextLine>{t(`${TP}.fcny`, 'FCNY')}</StoreTextLine>
              <StoreTextLine>{t(`${TP}.fcnyAddress`, '812 Broadway, New York City')}</StoreTextLine>
            </StoreContainer>
            <StoreContainer>
              <StoreTextLine>{t(`${TP}.fcla`, 'FCLA')}</StoreTextLine>
              <StoreTextLine>{t(`${TP}.fclaAddress`, '535 N Fairfax, Los Angeles')}</StoreTextLine>
            </StoreContainer>
            <StoreContainer>
              <StoreTextLine>{t(`${TP}.fcMiami`, 'FC Miami')}</StoreTextLine>
              <StoreTextLine>{t(`${TP}.fcMiamiAddress`, '3910 NE 1st Ave, Miami')}</StoreTextLine>
            </StoreContainer>
          </Section>
        </UnstyledLink>
      </Link>
    </ExpandedMenuModal>
  )
}

export default MobileMenu
