import { Form, Formik } from 'formik'
import { omitBy } from 'lodash'
import getConfig from 'next/config'
import { useRouter } from 'next/router'
import { FC, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import styled, { css } from 'styled-components'
import * as Yup from 'yup'

import Bugsnag, { NotifiableError } from '@bugsnag/js'
import { useSetRegionPreferencesMutation } from 'api/regionPreferencesApi'
import { useLoginMutation } from 'api/usersApi'
import ErrorBanner from 'errors/components/ErrorBanner'
import { IError } from 'errors/types/IError'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import { t } from 'localization'
import {
  logLoginOrCreateAccountClick,
  logUserLoginOrCreation,
  mParticleIdentifyUser,
} from 'mParticle/mParticleService'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { emailValidation } from 'shared/addressValidations'
import { Button } from 'shared/components/Button'
import { FCLink } from 'shared/components/Links'
import Spinner from 'shared/components/Spinner'
import { useShoppingRegionContext } from 'shared/contexts/ShoppingRegionContextProvider'
import { UserAuthQA } from 'shared/dataAttributes'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { colors, fonts } from 'shared/lib'
import { trackImpactIdentify } from 'tracking/impactService'
import AuthFormField, { FieldType } from './AuthFormField'

const TP = 'login.components.LoginForm'

type CreateAccountInsteadStyle = 'button' | 'buttonAndDescription' | 'linkAndDescription'

interface ILoginFormProps {
  onCreateAccountClick?(): void
  onLogin(): void
  onLoginError(errors: IError[]): void
  isCreateAccountOptionHidden?: boolean
  isDarkMode?: boolean
  isEmailDisabled?: boolean
  initialEmail?: string
  buttonText?: string
  createAccountInsteadStyle?: CreateAccountInsteadStyle
  createAccountInsteadTitle?: string
  createAccountInsteadDescription?: string
  location: 'pdp_favorite' | 'global_favorite_panel' | 'account_page'
  favoritesFlow?: boolean
  handleCloseLoginModal?: () => void
}

const LoginForm: FC<ILoginFormProps> = ({
  onLogin,
  onCreateAccountClick,
  onLoginError,
  isCreateAccountOptionHidden = false,
  isDarkMode = false,
  initialEmail,
  isEmailDisabled,
  buttonText = t(`${TP}.logIn`, 'Log in'),
  createAccountInsteadStyle = 'button',
  createAccountInsteadTitle,
  createAccountInsteadDescription = t(
    `${TP}.createAccountInsteadDescription`,
    'New to Flight Club?',
  ),
  location,
  favoritesFlow,
  handleCloseLoginModal,
}) => {
  const {
    publicRuntimeConfig: { googleRecaptchaPublicSiteKey },
  } = getConfig()
  const isGoogleRecaptchaEnabled = useFeatureFlag(FeatureFlag.WEB_FC_ENABLE_GOOGLE_RECAPTCHA)
  const formRef = useRef(null)
  const recaptchaRef = useRef(null)
  const [showCaptcha, setShowCaptcha] = useState(false)
  const [captchaError, setCaptchaError] = useState('')
  const [captchaToken, setCaptchaToken] = useState('')
  const router = useRouter()
  const { query, locale } = router
  const { country } = useShoppingRegionContext()
  // get query param to force Viceroy bot detection (used to test reCaptcha deterministically)
  const forceViceroy = query?.force_viceroy

  const [login, { data: loginData, error: loginError, isError, isLoading, isSuccess }] =
    useLoginMutation()
  const [setRegionPreference] = useSetRegionPreferencesMutation()

  const LoginSchema = Yup.object({
    email: emailValidation(),
    password: Yup.string()
      .min(1)
      .required(t(`${TP}.passwordValidation`, 'Password is a required field.')),
  })

  const initialLoginFields = {
    email: initialEmail || '',
    password: '',
  }

  const handleLogin = async (formValues) => {
    setShowCaptcha(false)
    const { email, password } = formValues
    logLoginOrCreateAccountClick({ eventType: 'login', email, location })
    const user = omitBy(
      {
        login: email.trim(),
        password,
        passcode: captchaToken,
        provider: captchaToken ? 'recaptcha' : '',
        forceViceroy: captchaToken ? '' : forceViceroy,
      },
      (x) => !x,
    )
    onLoginError([])
    await login(user)
  }

  const handleLoginComplete = async () => {
    const regionPreferencesBody = {
      region_preferences: {
        country,
        locale,
      },
    }
    try {
      await setRegionPreference(regionPreferencesBody)
    } catch (error) {
      Bugsnag.notify(error as NotifiableError)
    }
    mParticleIdentifyUser(loginData, 'login')
    logUserLoginOrCreation({ eventType: 'user_login', email: loginData?.email })
    trackImpactIdentify(loginData)
    onLogin()
    onLoginError([])
  }

  const handleLoginError = () => {
    // This is the error message from Sneakers when Viceroy detects
    // suspicious login attempts and blocks them. We show users a
    // captcha in order to unblock them.
    const isSuspicious =
      loginError?.data?.auth_error === 'captcha_passcode_required' ||
      loginError?.data?.messages?.[0] ===
        'For your security, please complete the captcha and try logging in again.'

    if (isSuspicious) {
      setShowCaptcha(true)
      setCaptchaError(loginError?.data?.localized_messages?.[0] || loginError?.data?.messages?.[0])
      sendTrackingEvent('CAPTCHA_VIEW', {
        page: router.pathname,
      })
    } else {
      onLoginError(
        loginError?.data?.localized_messages ||
          loginError?.data?.messages || [loginError?.data?.message],
      )
    }
  }

  const handleChangeCaptcha = (token: string) => {
    setCaptchaToken(token)
  }

  useEffect(() => {
    if (captchaError) {
      setTimeout(() => {
        setCaptchaError('')
      }, 5000)
    }
  }, [captchaError])

  useEffect(() => {
    if (captchaToken) {
      formRef.current.handleSubmit(login)
    }
  }, [captchaToken])

  useEffect(() => {
    if (isError) {
      handleLoginError()
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      handleLoginComplete(loginData)
    }
  }, [isSuccess])

  const handleForgotPasswordClick = () => {
    if (favoritesFlow && handleCloseLoginModal) {
      handleCloseLoginModal()
    }
  }

  return (
    <Spinner
      showSpinner={isLoading}
      text={t(`${TP}.spinnerText`, 'Logging in...')}
      subText={t(`${TP}.spinnerSubText`, 'Please do not close your browser!')}
    >
      {/*
            enableReinitialize is necessary for the forced rerender,
            see ManageOrderCreateAccount
          */}
      <Formik
        enableReinitialize
        initialValues={initialLoginFields}
        innerRef={formRef}
        onSubmit={handleLogin}
        validationSchema={LoginSchema}
      >
        {({ dirty, isValid }) => (
          <Form>
            <AuthFormField
              title={t(`${TP}.emailAddress`, 'Email Address')}
              name="email"
              type={FieldType.Email}
              qaAttr={UserAuthQA.LoginEmailBox}
              isDarkMode={isDarkMode}
              disabled={isEmailDisabled}
            />
            <AuthFormField
              title={t(`${TP}.password`, 'Password')}
              name="password"
              type={FieldType.CurrentPassword}
              qaAttr={UserAuthQA.LoginPassBox}
              isDarkMode={isDarkMode}
            />
            <LoginActions>
              <ForgotPassword
                href="/customer/account/forgotpassword"
                isDarkMode={isDarkMode}
                $showCaptcha={showCaptcha}
                qaAttr={UserAuthQA.LoginForgotPasswordLink}
                onClick={handleForgotPasswordClick}
              >
                {t(`${TP}.forgotPassword`, 'Forgot Password?')}
              </ForgotPassword>
              {isGoogleRecaptchaEnabled && showCaptcha && (
                <>
                  {captchaError && (
                    <CaptchaErrorMessage message={captchaError} qaAttr="captcha_error_message" />
                  )}
                  <ReCAPTCHA
                    sitekey={googleRecaptchaPublicSiteKey}
                    onChange={handleChangeCaptcha}
                    ref={recaptchaRef}
                  />
                </>
              )}
              <SubmitButton
                $fill
                buttonType="primary2"
                disabled={!(isValid && dirty) || showCaptcha}
                type="submit"
                qaAttr={UserAuthQA.PrimaryLoginButton}
                isDarkMode={isDarkMode}
              >
                {buttonText}
              </SubmitButton>
              {!isCreateAccountOptionHidden && (
                <>
                  {createAccountInsteadStyle === 'linkAndDescription' && (
                    <>
                      <Divider />
                      <LinkAndDescription>
                        <LinkDescription>{createAccountInsteadDescription}</LinkDescription>
                        <LinkButton
                          $fill
                          buttonType="link"
                          component="button"
                          onClick={onCreateAccountClick}
                          qaAttr={UserAuthQA.SecondaryCreateAccountButton}
                        >
                          {t(`${TP}.createAccount`, 'Create Account')}
                        </LinkButton>
                      </LinkAndDescription>
                    </>
                  )}
                  {createAccountInsteadStyle === 'buttonAndDescription' && (
                    <>
                      <Divider />
                      {!!createAccountInsteadTitle && (
                        <CreateAccountInsteadTitle>
                          {createAccountInsteadTitle}
                        </CreateAccountInsteadTitle>
                      )}
                      <CreateAccountDescription isDarkMode={isDarkMode}>
                        {createAccountInsteadDescription}
                      </CreateAccountDescription>
                      <Button
                        $fill
                        buttonType="secondary"
                        component="button"
                        onClick={onCreateAccountClick}
                        qaAttr={UserAuthQA.SecondaryCreateAccountButton}
                      >
                        {t(`${TP}.createAccount`, 'Create Account')}
                      </Button>
                    </>
                  )}
                  {createAccountInsteadStyle === 'button' && (
                    <>
                      <Divider />
                      <Text data-qa={UserAuthQA.NewToFlightClubText}>
                        {createAccountInsteadDescription}
                      </Text>
                      <StyledButton
                        $fill
                        buttonType="secondary"
                        component="button"
                        onClick={onCreateAccountClick}
                        qaAttr={UserAuthQA.SecondaryCreateAccountButton}
                      >
                        {t(`${TP}.createAccount`, 'Create Account')}
                      </StyledButton>
                    </>
                  )}
                </>
              )}
            </LoginActions>
          </Form>
        )}
      </Formik>
    </Spinner>
  )
}

const ForgotPassword = styled(FCLink)<{ isDarkMode: boolean; $showCaptcha: boolean }>`
  ${fonts.LINK};
  ${({ $showCaptcha }) =>
    $showCaptcha &&
    css`
      margin-bottom: 20px;
    `}
  -webkit-tap-highlight-color: transparent;
  align-self: start;
  color: ${({ isDarkMode }) => (isDarkMode ? colors.FC2_WHITE : colors.FC2_GREY)};
`

const CaptchaErrorMessage = styled(ErrorBanner)`
  padding-bottom: 10px;
  padding-left: 12px;
  padding-top: 10px;
`

const SubmitButton = styled(Button)`
  margin-top: 20px;
  line-height: normal;
`

const LoginActions = styled.div`
  display: flex;
  flex-direction: column;
`
const Divider = styled.div`
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
  margin: 25px 0;
`
const CreateAccountDescription = styled.p<{ isDarkMode: boolean }>`
  ${fonts.SUBTITLE_3}
  margin: 0 0 20px;
  color: ${({ isDarkMode }) => (isDarkMode ? colors.FC2_WHITE : colors.FC2_GREY)};
`
const CreateAccountInsteadTitle = styled.h3`
  ${fonts.SUBTITLE_2};
  margin-top: 0;
`
const LinkAndDescription = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const LinkButton = styled(Button)`
  color: ${colors.FC2_BLACK};
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  &:hover {
    color: ${colors.FC2_DARK_GREY};
  }
`
const LinkDescription = styled.span`
  ${fonts.SUBTITLE_2}
  color: ${colors.FC2_DARK_GREY};
`
const Text = styled.div`
  color: ${colors.FC2_BLACK};
  margin-bottom: 16px;
  font-weight: 500;
  line-height: normal;
  font-size: 12px;
`

const StyledButton = styled(Button)`
  line-height: normal;
`
export default LoginForm
