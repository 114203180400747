import { MutableRefObject, useState, useEffect } from 'react'
import styled from 'styled-components'

import { colors } from 'shared/lib'
import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { FavoritesListContents } from 'favorites/FavoritesListContents'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { SlideOutPanel } from 'shared/components/SlideOutPanel'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { t } from 'localization'
import { useAppSelector } from 'store/hooks'
import { useClickOutside } from 'shared/hooks'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'

export const FavoritesSlideOutPanel = ({
  setRenderPortal,
}: {
  setRenderPortal: (status: boolean) => void
}) => {
  const TP = 'favorites.components.FavoritesSlideOutPanel'
  const [renderSlideOut, setRenderSlideOut] = useState(true)

  const isFavoritesEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_FAVORITES)

  const { isDeleteSizeModalOpen } = useAppSelector((state) => state.favorites)

  const ref: MutableRefObject<HTMLDivElement | null> = useClickOutside(() => {
    if (isDeleteSizeModalOpen) return
    setRenderSlideOut(false)
  })

  const onAnimationStart = () => {
    if (!renderSlideOut) {
      setTimeout(() => setRenderPortal(false), 300)
    }
  }

  const handleClose = () => {
    setRenderSlideOut(false)
    setTimeout(() => setRenderPortal(false), 300)
  }

  useEffect(() => {
    sendTrackingEvent('FAVORITE_PANEL_VIEW', { is_logged_in: true })
  }, [])

  if (!isFavoritesEnabled) {
    return null
  }

  return (
    <>
      <ClientOnlyPortal
        selector=".main-page-layout"
        withBackdrop
        itemsAlignment="end"
        id="favorites-slide-out-panel"
      >
        <StyledSlideOutPanel
          ref={ref}
          className={renderSlideOut ? 'enter' : 'exit'}
          onAnimationStart={onAnimationStart}
        >
          <FavoritesSlideOutHeader data-qa="FavoritesSlideOutHeader">
            {t(`${TP}.navLinkText`, 'Favorites')}
            <FavoritesSlideOutCloseButton
              data-qa="FavoritesSlideOutPanelCloseButton"
              onClick={handleClose}
            >
              <CloseX />
            </FavoritesSlideOutCloseButton>
          </FavoritesSlideOutHeader>
          <FavoritesListContents
            isDeleteSizeModalOpen={isDeleteSizeModalOpen}
            handleClose={handleClose}
          />
        </StyledSlideOutPanel>
      </ClientOnlyPortal>
    </>
  )
}

const StyledSlideOutPanel = styled(SlideOutPanel)`
  background-color: ${colors.FC2_OFF_WHITE};
`

const FavoritesSlideOutHeader = styled.div`
  background-color: ${colors.FC2_WHITE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  height: 72px;
  padding: 27px 20px;
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const FavoritesSlideOutCloseButton = styled.button`
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
`
