import { MutableRefObject, useState, useEffect } from 'react'
import styled from 'styled-components'

import { Button } from 'shared/components/Button'
import { colors } from 'shared/lib'
import { ClientOnlyPortal } from 'shared/components/ClientOnlyPortal'
import { CloseX } from 'shared/components/Icons/SVGIcons/CloseX'
import { FeatureFlag } from 'shared/enums/FeatureFlag'
import { SlideOutPanel } from 'shared/components/SlideOutPanel'
import sendTrackingEvent from 'mParticle/sendTrackingEvent'
import { sizes } from 'shared/lib/media'
import { t } from 'localization'
import { useClickOutside } from 'shared/hooks'
import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'

type LoginInstructionsProps = {
  setRenderPortal: (status: boolean) => void
  handleOpenLoginPanelModal: () => void
}

export const LoginInstructions = ({
  setRenderPortal,
  handleOpenLoginPanelModal,
}: LoginInstructionsProps) => {
  const TP = 'favorites.components.LoginInstructions'
  const [renderSlideOut, setRenderSlideOut] = useState(true)

  const isFavoritesEnabled = useFeatureFlag(FeatureFlag.TEMP_WEB_FC_ENABLE_FAVORITES)

  const ref: MutableRefObject<HTMLDivElement | null> = useClickOutside(() => {
    setRenderSlideOut(false)
  })

  const onAnimationStart = () => {
    if (!renderSlideOut) {
      setTimeout(() => setRenderPortal(false), 300)
    }
  }

  const handleClose = () => {
    setRenderSlideOut(false)
    setTimeout(() => setRenderPortal(false), 300)
  }

  const handleLoginClick = () => {
    handleOpenLoginPanelModal()
    setRenderSlideOut(false)
  }

  useEffect(() => {
    sendTrackingEvent('FAVORITE_PANEL_VIEW', { is_logged_in: false })
  }, [])

  if (!isFavoritesEnabled) {
    return null
  }

  return (
    <ClientOnlyPortal
      selector=".main-page-layout"
      withBackdrop
      itemsAlignment="end"
      id="login-instructions"
    >
      <StyledSlideOutPanel
        ref={ref}
        className={renderSlideOut ? 'enter' : 'exit'}
        onAnimationStart={onAnimationStart}
      >
        <FavoritesSlideOutHeader data-qa="FavoritesSlideOutHeader">
          {t(`${TP}.headerText`, 'Favorites')}
          <FavoritesSlideOutCloseButton
            data-qa="LoginInstructionsCloseButton"
            onClick={handleClose}
          >
            <CloseX />
          </FavoritesSlideOutCloseButton>
        </FavoritesSlideOutHeader>
        <LoginPrompt data-qa="LoginInstructions">
          <span>
            {t(
              `${TP}.loginPromptCta`,
              'Log in to your Flight Club account to view your Favorites.',
            )}
          </span>
          <Button data-qa="LoginButton" onClick={handleLoginClick}>
            {t(`${TP}.loginButtonCta`, 'Log In')}
          </Button>
        </LoginPrompt>
      </StyledSlideOutPanel>
    </ClientOnlyPortal>
  )
}

const StyledSlideOutPanel = styled(SlideOutPanel)`
  background-color: ${colors.FC2_OFF_WHITE};
`

const FavoritesSlideOutHeader = styled.div`
  background-color: ${colors.FC2_WHITE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: ${colors.FC2_BLACK};
  height: 72px;
  padding: 27px 20px;
  border-bottom: 1px solid ${colors.FC2_LIGHTEST_GREY};
`

const FavoritesSlideOutCloseButton = styled.button`
  width: 15px;
  height: 15px;
  margin-bottom: 3px;
`

const LoginPrompt = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 59px;

  > span {
    font-family: 'Helvetica Neue';
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    width: 323px;
    margin: 30px 0px;
  }
  > button {
    height: 45px;
    max-width: 390px;
    text-transform: uppercase;
    line-height: normal;
  }

  @media (max-width: ${sizes.large / 16}em) {
    margin-bottom: 0px;
    > button {
      width: 335px;
      margin-bottom: 40px;
      line-height: normal;
    }
  }
`
