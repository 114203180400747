import { PriceUpdateModal } from 'checkout/components/ShippingAddressPanel/PriceUpdateModal'
import Cookies from 'js-cookie'
import { LANGUAGE_COOKIE_NAME } from 'localization'
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { STORAGE } from 'shared/enums/SitePreferences'
import useCurrency from 'shared/hooks/useCurrency'
import { Country } from 'shared/types/Country'
import { ICurrency } from 'shared/types/ICurrency'
import { ShoppingRegionCountry } from 'shared/types/RegionPreference'

type ShoppingRegionContextProviderProps = {
  children: ReactNode
  serverValues: ShoppingRegionCountry
  availableCountries: Country[]
  availableCurrencies: ICurrency[]
  clientSideLocale: string
}

type ShoppingRegionState = {
  country: string
  setCountryContext: Dispatch<SetStateAction<string>>
  countryName: string
  setCountryNameContext: Dispatch<SetStateAction<string>>
  currencyCode: string
  setCurrencyCodeContext: Dispatch<SetStateAction<string>>
  availableCountries: Country[]
  availableCurrencies: ICurrency[]
  /** Keeps track if due to a shopping/shipping region update an order price is updated. */
  isOrderPriceUpdated: boolean
  setIsOrderPriceUpdated: Dispatch<SetStateAction<boolean>>
  locale: string
  setLocaleContext: Dispatch<SetStateAction<string>>
}

const ShoppingRegionContext = createContext<ShoppingRegionState>({
  country: '',
  setCountryContext: () => {},
  countryName: '',
  setCountryNameContext: () => {},
  currencyCode: '',
  setCurrencyCodeContext: () => {},
  availableCountries: [],
  availableCurrencies: [],
  isOrderPriceUpdated: false,
  setIsOrderPriceUpdated: () => null,
  locale: '',
  setLocaleContext: () => null,
})

export const useShoppingRegionContext = () => useContext(ShoppingRegionContext)

const ShoppingRegionContextProvider = ({
  children,
  serverValues,
  availableCountries,
  availableCurrencies,
}: ShoppingRegionContextProviderProps) => {
  const [locale, setLocaleContext] = useState(Cookies.get(LANGUAGE_COOKIE_NAME) || '')
  const [country, setCountryContext] = useState(
    Cookies.get(STORAGE.COUNTRY) || serverValues.country,
  )
  const [countryName, setCountryNameContext] = useState(
    Cookies.get(STORAGE.COUNTRYNAME) || serverValues.countryName,
  )
  const [currencyCode, setCurrencyCodeContext] = useState(
    Cookies.get(STORAGE.CURRENCYCODE) || serverValues.currencyCode,
  )
  const [isOrderPriceUpdated, setIsOrderPriceUpdated] = useState<boolean>(false)

  // set currency in backend
  const { setCurrency } = useCurrency()
  useEffect(() => {
    setCurrency(currencyCode)
  }, [])

  const expires = new Date()
  expires.setFullYear(expires.getFullYear() + 1)

  const shoppingRegionValues: ShoppingRegionState = {
    country,
    setCountryContext,
    countryName,
    setCountryNameContext,
    currencyCode,
    setCurrencyCodeContext,
    availableCountries,
    availableCurrencies,
    isOrderPriceUpdated,
    setIsOrderPriceUpdated,
    locale,
    setLocaleContext,
  }
  return (
    <ShoppingRegionContext.Provider value={shoppingRegionValues}>
      {children}
      <PriceUpdateModal
        isOpen={isOrderPriceUpdated}
        onClose={() => {
          setIsOrderPriceUpdated(false)
        }}
      />
    </ShoppingRegionContext.Provider>
  )
}

export default ShoppingRegionContextProvider
