import { useFeatureFlag } from 'featureFlags/hooks/useFeatureFlag'
import AvantSiteTracking from 'head/components/AvantSiteTracking'
import ImpactTag from 'head/components/ImpactTag'
import OneTrustTag from 'head/components/OneTrustTag'
import SnapchatPixel from 'head/components/SnapchatPixel'
import { FeatureFlag } from 'shared/enums/FeatureFlag'

type TrackingScriptsProps = {
  avantSiteID: string
}

const TrackingScripts = ({ avantSiteID }: TrackingScriptsProps) => {
  const isOneTrustEnabled = useFeatureFlag(FeatureFlag.ONE_TRUST)
  const isImpactTrackingEnabled = useFeatureFlag(FeatureFlag.IMPACT_TRACKING)

  return (
    <>
      {isOneTrustEnabled && <OneTrustTag />}
      {avantSiteID && <AvantSiteTracking isOneTrustEnabled={isOneTrustEnabled} />}
      {isImpactTrackingEnabled && <ImpactTag isOneTrustEnabled={isOneTrustEnabled} />}
      <SnapchatPixel isOneTrustEnabled={isOneTrustEnabled} />
    </>
  )
}

export default TrackingScripts
