import { FavoriteGroupedByPT } from 'favorites/types'
import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { productTemplateListsApi } from 'api/productTemplateListsApi'
import { usersApi } from 'api/usersApi'

type FavoritesState = {
  favoritedSizesForAllPTs: {
    listItems: FavoriteGroupedByPT[]
    itemsPerPage: number
    paginationToken?: string
  }[]
  currentPaginationToken: string | undefined
  nextPageQueue: string[]
  favoriteToBeRemoved: {
    productTemplateId: string
    sizes: number[]
    productTemplateSlug: string
  } | null
  userLoggedInFromFavorites: boolean
  isDeleteSizeModalOpen: boolean
  isSaveFavoritesModalOpen: boolean
}

const initialState: FavoritesState = {
  favoritedSizesForAllPTs: [],
  currentPaginationToken: 'start',
  nextPageQueue: ['start'],
  favoriteToBeRemoved: null,
  userLoggedInFromFavorites: false,
  isDeleteSizeModalOpen: false,
  isSaveFavoritesModalOpen: false,
}

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    setCurrentPaginationToken: (state) => {
      state.currentPaginationToken = state.nextPageQueue[0]
      state.nextPageQueue = state.nextPageQueue.slice(1)
    },
    setFavoriteToBeRemoved: (
      state,
      action: PayloadAction<{
        productTemplateId: string
        sizes: number[]
        productTemplateSlug: string
      }>,
    ) => {
      state.favoriteToBeRemoved = action.payload
      state.isDeleteSizeModalOpen = true
    },
    setUserLoggedInFromFavoritesFlow: (state, action: PayloadAction<boolean>) => {
      state.userLoggedInFromFavorites = action.payload
    },
    setIsDeleteSizeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeleteSizeModalOpen = action.payload
    },
    setIsSaveFavoritesModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSaveFavoritesModalOpen = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productTemplateListsApi.endpoints.fetchAllUserFavorites.matchFulfilled,
      (state, action) => {
        // Remove the last pagination token from the queue since it has been used already
        state.nextPageQueue = state.nextPageQueue.slice(1)

        // Check if the newest page has a pagination token for the next page
        // Make sure there are no duplicated tokens in queue
        if (
          action?.payload.paginationToken &&
          !state.nextPageQueue.includes(action.payload.paginationToken)
        ) {
          state.nextPageQueue = [...state.nextPageQueue, action.payload.paginationToken]
        }

        // At start of page 1 return an array with first response
        // If user removes all favorites, return empty array to display empty state message
        if (state.currentPaginationToken === 'start') {
          const payload = Object.keys(action.payload)?.length > 0 ? [action.payload] : []
          state.favoritedSizesForAllPTs = payload
        } else {
          // merge into one array for infinite scroll
          state.favoritedSizesForAllPTs = [...state.favoritedSizesForAllPTs, action.payload]
        }
      },
    )
    builder.addMatcher(
      productTemplateListsApi.endpoints.saveUserFavorites.matchFulfilled,
      (state) => {
        // reset state to initial state to refetch new sorted items
        state.currentPaginationToken = 'start'
        state.favoritedSizesForAllPTs = []
        state.nextPageQueue = ['start']
      },
    )
    builder.addMatcher(productTemplateListsApi.endpoints.deleteFavorite.matchFulfilled, (state) => {
      // reset state to initial state to refetch new sorted items
      state.currentPaginationToken = 'start'
      state.favoritedSizesForAllPTs = []
      state.nextPageQueue = ['start']
    })

    builder.addMatcher(usersApi.endpoints.login.matchFulfilled, (state) => {
      state.userLoggedInFromFavorites = false
    })
  },
})

const { actions, reducer: favoritesReducer } = favoritesSlice

export const {
  setCurrentPaginationToken,
  setFavoriteToBeRemoved,
  setUserLoggedInFromFavoritesFlow,
  setIsDeleteSizeModalOpen,
  setIsSaveFavoritesModalOpen,
} = actions
export { favoritesReducer }
